<template>
  <div id="Works" v-if="isThumbnail" class="home-panel works">
    <p class="title work-panel-title"><strong>W</strong>orks</p>
    <Row class="content border works-thumb">
      <Spin size="large" fix v-if="isLoading"></Spin>
      <Col v-for="(proj, index) in this.projects" class="project-cell"
      :xs="{span:24,offset:0}"
      :sm="{span:12,offset:0}"
      :md="{span:8,offset:0}"
      :lg="{span:6,offset:0}">
        <img :src="proj.image_url" v-on:load="getAppImage(proj)"/>
        <div>
          <p class="pj-title">{{proj.title}}</p>
          <p class="pj-description">{{proj.description}}</p>
          <p class="pj-link">
            <font-awesome-icon :icon="['fab', 'apple']" class="icon alt fa-fw"/><a :href="proj.apple_store_url" target="_blank">App Store</a><br/>
            <font-awesome-icon :icon="['fab', 'google-play']" class="icon alt fa-fw"/><a :href="proj.google_store_url" target="_blank">Google Play</a>
          </p>
        </div>
      </Col>
    </Row>
    <!--
    <router-link :to="this.router_link">
      <span class="read-more">rEAd mORe &gt;</span>
    </router-link>
    -->
  </div>
  <div v-else>
    <router-view/>
  </div>
</template>

<script>
import Axios from 'axios'
export default {
  name: 'Works',
  props: {
    isThumbnail: Boolean
  },
  data() {
    return {
      router_link: '/Works',
      message: 'TODO: /works',
      pageUrl: 'https://darkzero.me/blog/wp-json/wp/v2/projects?orderby=modified&order=asc',
      mediaUrl: 'https://darkzero.me/blog/wp-json/wp/v2/media/',
      projects: [],
      defaultAppImage: require("../assets/project_default.jpg"),
      isLoading : false
    }
  },
  created: function() {
    // https://darkzero.me/blog/wp-json/wp/v2/project
    this.isLoading = true
    Axios
      .get(this.pageUrl)
      .then(response => {
        let data = response.data
        data.forEach(item => {
          var proj = {
            'title' : item.title.rendered,
            'apple_store_url' : item.apple_store_url,
            'google_store_url' : item.google_store_url,
            'description' : item.app_description,
            'image_url' : require("../assets/project_default.jpg")
          }
          if (item.app_icon_image != undefined) {
            proj['image'] = item.app_icon_image
          }
          this.projects.push(proj)
        })
        this.isLoading = false
      })
      .catch(err => {
        this.errors.push(err)
      })
  },
  methods: {
    // load app icon image async
    getAppImage: function(proj) {
      if (proj.image != undefined) {
        Axios.get(this.mediaUrl+proj.image)
        .then(response => {
          let data = response.data
          proj.image_url = data.media_details.sizes.medium.source_url
        })
      }
    }
  }
}
</script>

<style scoped>
.work-panel-title {
  text-align: left;
}
/* .works-thumb {
  background-color: #e5e5e5;
} */
/* only for project */
.project-cell {
  display: inline-block;
  position: relative;
  padding: 0.4rem;
  text-align: left;
  vertical-align: top;
}
.project-cell img {
  display: inline-block;
  position: relative;
  background-clip: padding-box;
  background-color: #fff;
  vertical-align: top;
  object-fit: cover;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  width: 40%;
  height: 40%;
  max-width: 120px;
  max-height: 120px;
  border-radius: 1.2rem;
}
.project-cell>div {
  display: inline-block;
  position: relative;
  padding-left: 10px;
  max-width: 60%;
  max-height: 100%;
}
.project-cell>div>p {
  display: block;
  position: relative;
  max-width: 100%;
  padding: 0rem;
}
p.pj-title {
  color: inherit;
  font-size: 1.2rem;
  font-weight: 800;
}
p.pj-description {
  padding: 0.2rem;
  font-size: 0.8rem;
  font-weight: 300;
  color: #555
}
p.pj-link {
  font-size: .9rem;
  font-weight: 400;
}
/* p.pj-link>a {
  color: rgb(0, 113, 117);
} */
p.pj-link>a:before {
  content: " ";
}
/* p.pj-link>a:hover {
  color: #ff6633
} */
.read-more {
  display: block;
  position: relative;
  width: 100%;
  text-align: right;
}
</style>
