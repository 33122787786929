<template>
  <div>
    {{message}}
  </div>
</template>

<script>
export default {
  name: 'Other',
  data () {
    return {
      message: 'TODO: /works/other'
    }
  }
}
</script>

<style module>
  
</style>
