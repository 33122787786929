<template>
  <div class="work_div">
    <div class="content content-block corner-radius-all article-block">
      <div class="label-title">
        <center>{{message}}</center>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WorksMain',
  data () {
    return {
      message: '// TODO: Works'
    }
  }
}
</script>

<style lang="less" scoped>
.work_div {
  width: 80%;
  margin: 1rem auto 0 auto;
}
</style>